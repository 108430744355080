exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-berita-artikel-js": () => import("./../../../src/pages/berita/artikel.js" /* webpackChunkName: "component---src-pages-berita-artikel-js" */),
  "component---src-pages-berita-js": () => import("./../../../src/pages/berita.js" /* webpackChunkName: "component---src-pages-berita-js" */),
  "component---src-pages-hubungi-kami-js": () => import("./../../../src/pages/hubungi-kami.js" /* webpackChunkName: "component---src-pages-hubungi-kami-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keanggotaan-anggota-aktif-[id]-js": () => import("./../../../src/pages/keanggotaan/anggota-aktif/[id].js" /* webpackChunkName: "component---src-pages-keanggotaan-anggota-aktif-[id]-js" */),
  "component---src-pages-keanggotaan-anggota-aktif-js": () => import("./../../../src/pages/keanggotaan/anggota-aktif.js" /* webpackChunkName: "component---src-pages-keanggotaan-anggota-aktif-js" */),
  "component---src-pages-keanggotaan-pengurus-js": () => import("./../../../src/pages/keanggotaan/pengurus.js" /* webpackChunkName: "component---src-pages-keanggotaan-pengurus-js" */),
  "component---src-pages-kegiatan-galeri-js": () => import("./../../../src/pages/kegiatan/galeri.js" /* webpackChunkName: "component---src-pages-kegiatan-galeri-js" */),
  "component---src-pages-registrasi-js": () => import("./../../../src/pages/registrasi.js" /* webpackChunkName: "component---src-pages-registrasi-js" */),
  "component---src-pages-regulasi-circular-internal-js": () => import("./../../../src/pages/regulasi/circular-internal.js" /* webpackChunkName: "component---src-pages-regulasi-circular-internal-js" */),
  "component---src-pages-regulasi-kumpulan-peraturan-js": () => import("./../../../src/pages/regulasi/kumpulan-peraturan.js" /* webpackChunkName: "component---src-pages-regulasi-kumpulan-peraturan-js" */),
  "component---src-pages-regulasi-peraturan-bidang-alat-kesehatan-js": () => import("./../../../src/pages/regulasi/peraturan-bidang-alat-kesehatan.js" /* webpackChunkName: "component---src-pages-regulasi-peraturan-bidang-alat-kesehatan-js" */),
  "component---src-pages-regulasi-tugas-pengurus-pusat-js": () => import("./../../../src/pages/regulasi/tugas-pengurus-pusat.js" /* webpackChunkName: "component---src-pages-regulasi-tugas-pengurus-pusat-js" */),
  "component---src-pages-technopark-js": () => import("./../../../src/pages/technopark.js" /* webpackChunkName: "component---src-pages-technopark-js" */),
  "component---src-pages-tentang-kami-sejarah-js": () => import("./../../../src/pages/tentang-kami/sejarah.js" /* webpackChunkName: "component---src-pages-tentang-kami-sejarah-js" */),
  "component---src-pages-tentang-kami-visi-misi-js": () => import("./../../../src/pages/tentang-kami/visi-misi.js" /* webpackChunkName: "component---src-pages-tentang-kami-visi-misi-js" */),
  "component---src-templates-agenda-js": () => import("./../../../src/templates/agenda.js" /* webpackChunkName: "component---src-templates-agenda-js" */),
  "component---src-templates-artikel-js": () => import("./../../../src/templates/artikel.js" /* webpackChunkName: "component---src-templates-artikel-js" */),
  "component---src-templates-berita-js": () => import("./../../../src/templates/berita.js" /* webpackChunkName: "component---src-templates-berita-js" */),
  "component---src-templates-circular-internal-js": () => import("./../../../src/templates/circular-internal.js" /* webpackChunkName: "component---src-templates-circular-internal-js" */),
  "component---src-templates-galeri-js": () => import("./../../../src/templates/galeri.js" /* webpackChunkName: "component---src-templates-galeri-js" */),
  "component---src-templates-kumpulan-peraturan-js": () => import("./../../../src/templates/kumpulan-peraturan.js" /* webpackChunkName: "component---src-templates-kumpulan-peraturan-js" */),
  "component---src-templates-peraturan-bidang-alat-kesehatan-js": () => import("./../../../src/templates/peraturan-bidang-alat-kesehatan.js" /* webpackChunkName: "component---src-templates-peraturan-bidang-alat-kesehatan-js" */),
  "component---src-templates-tugas-pengurus-pusat-js": () => import("./../../../src/templates/tugas-pengurus-pusat.js" /* webpackChunkName: "component---src-templates-tugas-pengurus-pusat-js" */)
}

